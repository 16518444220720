<template>
  <echarts-com v-loading="loading" id="drainagePerformance-intersectionPrediction" :options="options"/>
</template>

<script>
import * as echarts from "echarts";
import {formatRatio} from "@/utils/util"
export default {
  components:{
    echartsCom:()=>import("@/components/echartsCom")
  },
  data() {
    return {
      options:{},
      list:[],
      loading:false
    }
  },
  created() {
    this.getOptions()
  },
  methods:{
    async getOptions(params={}){
      try {
        this.loading = true;
        let res = await this.$http.post("/statistics/channel/flow/chart",params);
        this.$set(this,"list",res.data);
        if (res.code === 0) {
          let xAxis_data = []
          let series = []
          let legend_data = []

          if(res.data.length){
            for(let item of res.data[0].infoList){
              xAxis_data.push(item.date)
            }
            for(let item of res.data){
              let values = []
              for(let items of item.infoList){
                values.push(items.value)
              }
              legend_data.push(item.name)
              series.push({
                name: item.name,
                type: 'line',
                stack: 'Total',
                data: values
              })
            }
          }

          this.$set(this, "options", {
            tooltip: {
              trigger: "axis",
              extraCssText: "box-shadow: 0px 0px 13px rgba(0, 193, 153, 0.22);",
            },
            legend: {
              right: "0%",
              top: '0%',
              data: legend_data,
              textStyle:{
                color:"white"
              }
            },
            xAxis: {
              type: "category",
              data: xAxis_data,
              axisTick: {
                show: false,
              },
              axisLine: {
                lineStyle: {
                  color: "#2a4d69",
                },
              },
              axisLabel: {
                color: "white",
              },
            },
            yAxis: {
              type: "value",
              splitLine: {
                lineStyle: {
                  color: "#2b4a6e",
                  type: "dashed",
                },
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#2a4d69",
                },
              },
              axisLabel: {
                color: "white",
              },
            },
            grid: {
              left: "0%",
              width: "100%",
              height: "90%",
              bottom: "0%",
              containLabel: true, // 包含坐标轴中的文字
            },
            series ,
          });
          this.getRanking(params)
          this.handleClick()
        }else{
          this.$message.error(res.msg);
        }
        this.loading = false;
      } catch(err) {
        this.$message.error("场景出口引流分析获取失败");
        this.loading = false;
      }
    },
    async getRanking(params){
      try {
        this.loading = true;
        let res = await this.$http.post("/statistics/channel/flow/rank",params);
        if (res.code === 0) {
          for(let item of res.data){
            item.percentage = formatRatio(item.percentage)
          }
          this.$emit("refreshRanking",res.data)
        }else{
          this.$message.error(res.msg);
        }
        this.loading = false;
      } catch(err) {
        this.$message.error("场景出口引流排名获取失败");
        this.loading = false;
      }
    },
    handleClick(){
      this.$nextTick(()=>{
        let exportDrainage = document.querySelector("#drainagePerformance-intersectionPrediction")
        echarts.init(exportDrainage).off("click");
        echarts.init(exportDrainage).on('click', (param)=> {

          let ranks = []
          for(let item of this.list){
            let rank = {}
            rank.name = item.name
            for(let items of item.infoList){
              if(items.date === param.name){
                rank.value = items.value
                break
              }
            }
            ranks.push(rank)
          }

          let total = 0
          for(let item of ranks){
            total+= item.value
          }
          for(let item of ranks){
            item.percentage = formatRatio(item.value/total)
          }
          this.$emit("refreshRanking",ranks)
        });
      })
    }
  }
}
</script>


